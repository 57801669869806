import { selectOrderStatus } from '../../Announcements/statusSlice'
import { currentOrders } from '../../../rest/urls'
import Loading from '../../../components/Loading'
import Banners from '../../../components/Banners'
import ChristmasOrder from './christmasOrder'
import { STATUS } from '../../../rest/status'
import { get } from '../../../rest/request'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Order from './order'
import './style.scss'

const CurrentOrders = () => {
  const orderStatus = useSelector(selectOrderStatus)
  const [status, setStatus] = useState(STATUS.pennding)
  const [data, setData] = useState()

  useEffect(() => {
    (async () => {
      try {
        const response = await get(currentOrders)
        setData(response)
        setStatus(STATUS.succeed)

      } catch (err) {
        setStatus(STATUS.failed)
        console.log(err)
      }
    })()
  }, [])

  return (
    <div className='orders__list--current'>
      {status === STATUS.pennding ? <Loading />
        : data && data.carts.length > 0 ? data.carts.map(({ status, tips, tip_definitions, odpowiedzi }, idx) => {
          const d = orderStatus?.[status.order_ident]
          const orderStatusKey = d ? d.status_key : status.order_status_key
          const statusKey = orderStatusKey || { step: 0, description: '' }

          return status.order_info ? (
            <ChristmasOrder
              key={idx}
              data={status}
              answers={odpowiedzi}
              status={statusKey}
              questions={data.pytania} />
          ) : (
            <Order
              key={idx}
              data={status}
              tips={tips}
              tipDefinitions={tip_definitions}
              answers={odpowiedzi}
              status={statusKey}
              questions={data.pytania} />
          )
        })
          : <div className='order'>
            <h2>Brak zamówień</h2>
          </div>}

          {status !== STATUS.pennding && <Banners />}
    </div>
  )
}

export default CurrentOrders