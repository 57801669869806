import { ReactComponent as Dots } from '../../../assets/icons/animatedDots.svg'
import './style.scss'

export const TEXT_BTN_TYPE = {
  border: '--border',
  normal: '--normal',
  reduced: '--reduced',
  unfilled: '--unfilled',
}

const TextBtn = ({ desc, isLoading, type = TEXT_BTN_TYPE.normal, clickAction }) => {
  return (
    <button
      className={`button__text ${type}`}
      onClick={isLoading ? undefined : clickAction}>
      {isLoading ? <Dots /> : desc}
    </button>
  )
}

export default TextBtn